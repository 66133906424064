import {Link} from 'gatsby';

import Meta from '@components/meta';
import Container from '@components/container';

import * as style from '@styles/error.module.scss';

const ErrorNotFoundPage = () => (

  <Container appendClass={style.container}>
    <Meta
      title="何かお探しですか？ | Slope スロープ"
      description=""
    >
      <link href="https://fonts.googleapis.com/css?family=Share+Tech+Mono" rel="stylesheet" />
    </Meta>

    <main className={style.error__wrapper} role="main">
      <div className={style.error__display}>404</div>
      <p className={style.error__link}>
        <Link to="/">Home</Link>
      </p>
    </main>
  </Container>

);

export default ErrorNotFoundPage;
